import { gql } from 'graphql-request';

import { RawFullUserBooking } from './bookingDetailsInterfaces';
import { DavinciClient } from './DavinciClient';

export type BookingPaymentStatusQueryResponse = {
	bookings: {
		paymentTerms: RawFullUserBooking['paymentTerms'];
	}[];
};

export const getBookingPaymentStatus = async (
	token: string,
	bookingNumber: number,
): Promise<BookingPaymentStatusQueryResponse | undefined> => {
	try {
		return await DavinciClient.request<BookingPaymentStatusQueryResponse, { bookingNumber: number }>({
			signal: AbortSignal.timeout(20000),
			document: bookingPaymentStatusQuery,
			variables: { bookingNumber },
			requestHeaders: { Authorization: `Bearer ${token}` },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(`Error fetching booking payment status for booking number ${bookingNumber}`, error);
	}
};

const bookingPaymentStatusQuery = gql`
	query GetBookingPaymentStatus($bookingNumber: Int) {
		bookings(where: { bookingNumber: { _eq: $bookingNumber } }) {
			paymentTerms {
				termOpenAmount
				isPaid
				dueDate
				termNumber
			}
		}
	}
`;
