import { FC } from 'react';

import { Button } from '@vakantiesnl/components/src/__LEGACY__/atoms/Button';
import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { MicroCopy } from '@vakantiesnl/types';

import styles from './ErrorMessage.module.css';

type Props = {
	microCopy: MicroCopy;
	title: string;
	description: string;
};

export const ErrorMessage: FC<Props> = ({ microCopy, title, description }) => (
	<div className={styles.container}>
		<Heading className={styles.title} headingLevel="h1">
			{title}
		</Heading>
		<p className={styles.text}>{description}</p>
		<Button variant="primary" title={microCopy['redirect.homepage']} link={'/'} />
	</div>
);
