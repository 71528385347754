import { gql } from 'graphql-request';

import { RawFullUserBooking } from './bookingDetailsInterfaces';
import { DavinciClient } from './DavinciClient';

export type BookingDetailsQueryResponse = { bookings: RawFullUserBooking[] } | undefined;

export const getBookingDetails = async (token: string, bookingNumber: number): Promise<BookingDetailsQueryResponse> => {
	try {
		return DavinciClient.request<BookingDetailsQueryResponse, { bookingNumber: number }>({
			signal: AbortSignal.timeout(20000),
			document: bookingDetailsQuery,
			variables: { bookingNumber },
			requestHeaders: { Authorization: `Bearer ${token}` },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(`Error fetching booking details for bookingNumber ${bookingNumber}`, error);
	}
};

const bookingDetailsQuery = gql`
	query GetBookingDetails($bookingNumber: Int) {
		bookings(where: { bookingNumber: { _eq: $bookingNumber } }) {
			bookingNumber
			departureDate
			transportCode
			numberOfPassengers
			returnDate
			entityId
			firstServiceName
			mealplan
			duration
			isCancelled
			amountTotal
			bookingAmountOpen
			bookingDocuments {
				fileName
				createDate
				bookingDocumentId
				documentType
			}
			bookingServicePrices {
				description
				totalPrice
			}
			payments {
				amountPaid
				paymentDate
			}
			paymentTerms {
				termOpenAmount
				isPaid
				dueDate
				termNumber
			}
			contactDetails {
				city
				countryCode
				emailAddress
				firstName
				houseNumber
				lastName
				street
				zipCode
				mobilePhoneNumber
			}
			luggage: bookingServices(where: { serviceType: { _eq: "SO*" } }) {
				endDate
				serviceExternalRef
				startDate
				serviceLuggageWeight
				bookingServiceParticipants {
					bookingParticipantId
				}
			}
			transfers: bookingServices(where: { serviceType: { _eq: "T*" } }) {
				serviceTypeName
			}
			rentalCars: bookingServices(where: { serviceType: { _eq: "MW" } }) {
				passengerAssignment
				bookingServiceRentalCars {
					rentalCarType
					supplierType
					remark
				}
			}
			flights: bookingServices(where: { serviceType: { _eq: "F*" } }) {
				supplierCode
				startDate
				endDate
				bookingServiceFlights {
					bookingServiceExternalReference
					flightAirlineCode
					flightAirlineName
					flightArrivalAirport
					flightArrivalAirportName
					flightArrivalDate
					flightDepartureAirport
					flightDepartureAirportName
					flightDepartureDatee
					flightSequence
					flightNumber
				}
			}
			rooms: bookingServices(where: { serviceType: { _eq: "H*" } }) {
				duration
				bookingServiceParticipants {
					bookingParticipant {
						orderNumber
						firstName
						lastName
						dateOfBirth
					}
				}
				bookingServiceAccommodations {
					roomTypeName
				}
			}
		}
	}
`;
