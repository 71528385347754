import { useEffect } from 'react';

import { NextPage, NextPageContext } from 'next';

import { Content } from '@vakantiesnl/components/src/__LEGACY__/atoms/Content';
import { ErrorMessage as BaseErrorMessage } from '@vakantiesnl/components/src/__LEGACY__/molecules/ErrorMessage';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';

import ErrorLayout from '../layout/error';

type Props = {
	statusCode: number;
	description?: string;
	title?: string;
};

const ErrorMessage: React.FC<Props> = ({ statusCode, title, description }) => {
	const microCopies = useMicroCopyContext();
	if (statusCode === 404) {
		return (
			<BaseErrorMessage
				microCopy={microCopies}
				title={title || microCopies['error.not.found.title']}
				description={description || microCopies['error.not.found.description']}
			/>
		);
	}

	if (statusCode && statusCode >= 500 && statusCode <= 511) {
		return (
			<BaseErrorMessage
				microCopy={microCopies}
				title={microCopies['error.unexpected.title']}
				description={microCopies['error.unexpected.description']}
			/>
		);
	}

	return (
		<BaseErrorMessage
			microCopy={microCopies}
			title={title || microCopies['error.unknown.title']}
			description={description || microCopies['error.unknown.description']}
		/>
	);
};

const CustomError: NextPage<Props> = (props) => {
	const { title, statusCode } = props;
	const microCopies = useMicroCopyContext();
	let errorTitle: string;
	if (title) {
		errorTitle = title;
	} else if (statusCode === 404) {
		errorTitle = microCopies['error.not.found.title'];
	} else if (statusCode && statusCode >= 500 && statusCode <= 511) {
		errorTitle = microCopies['error.unexpected.title'];
	} else {
		errorTitle = microCopies['error.unknown.title'];
	}

	/** On mount dispatch the errorPageView tracking */
	useEffect(() => {
		import('@vakantiesnl/services/src/gtm/common').then(({ track }) =>
			import('@vakantiesnl/services/src/gtm/errorPage').then(({ errorPageView }) =>
				track(errorPageView({ statusCode, errorMessage: errorTitle, sub_page: 'Vakanties.nl error page' })),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorLayout title={errorTitle}>
			<Content>
				<ErrorMessage {...props} />
			</Content>
		</ErrorLayout>
	);
};

CustomError.getInitialProps = ({ res, err }: NextPageContext): Props => {
	const statusCode = res ? res.statusCode : err ? (err.statusCode as number) : 404;
	return { statusCode };
};

export default CustomError;
