import { gql } from 'graphql-request';

import { DavinciClient } from './DavinciClient';
import { getDateYMD } from '../util/dateUtils';

export type CompactUserBooking = {
	bookingNumber: number;
	departureDate: string; // format YYYY-MM-DD
	returnDate: string; // format YYYY-MM-DD
	entityId: string;
	mealplan: string;
	numberOfPassengers: number;
	isCancelled: boolean;
	transportCode: 'VL' | 'EV'; // VL = with flight, EV = with own transport
};

type BookingsQueryResponse = { bookings: CompactUserBooking[] };

export const getUpcomingBookings = async (token: string): Promise<BookingsQueryResponse | undefined> => {
	try {
		return await DavinciClient.request<BookingsQueryResponse, { currentDate: string; organisation: string }>({
			signal: AbortSignal.timeout(20000),
			document: upcomingBookingsQuery,
			variables: {
				currentDate: getDateYMD(new Date()),
				organisation: process.env.NEXT_PUBLIC_DAVINCI_ORGANISATION as string,
			},
			requestHeaders: { Authorization: `Bearer ${token}` },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching upcoming bookings', error);
	}
};

export const getPastAndCancelledBookings = async (token: string): Promise<BookingsQueryResponse | undefined> => {
	try {
		return await DavinciClient.request<BookingsQueryResponse, { currentDate: string; organisation: string }>({
			signal: AbortSignal.timeout(20000),
			document: pastAndCancelledBookingsQuery,
			variables: {
				currentDate: getDateYMD(new Date()),
				organisation: process.env.NEXT_PUBLIC_DAVINCI_ORGANISATION as string,
			},
			requestHeaders: { Authorization: `Bearer ${token}` },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching past and cancelled bookings', error);
	}
};

const upcomingBookingsQuery = gql`
	query GetUpcomingBookings($currentDate: date, $organisation: String) {
		bookings(
			where: {
				returnDate: { _gte: $currentDate }
				isCancelled: { _eq: false }
				organisation: { _eq: $organisation }
				entityId: { _is_null: false }
			}
		) {
			bookingNumber
			transportCode
			departureDate
			numberOfPassengers
			returnDate
			entityId
			mealplan
			isCancelled
		}
	}
`;

const pastAndCancelledBookingsQuery = gql`
	query GetPastAndCancelledBookings($currentDate: date, $organisation: String) {
		bookings(
			where: {
				_or: [
					{
						returnDate: { _lt: $currentDate }
						organisation: { _eq: $organisation }
						giataId: { _is_null: false }
					}
					{ isCancelled: { _eq: true }, organisation: { _eq: $organisation }, entityId: { _is_null: false } }
				]
			}
			order_by: { returnDate: desc }
		) {
			bookingNumber
			departureDate
			numberOfPassengers
			returnDate
			entityId
			mealplan
			isCancelled
		}
	}
`;
