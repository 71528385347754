import { errorHandler, parseErrorToReport } from '../util/errorHandling';
import { USER_ROLES } from '../util/userRoles';

export type WhoAmIData = {
	acco_owner: boolean;
	aud: string;
	user_id: string;
	email: string;
	email_verified: boolean;
	role: USER_ROLES;
	firebase: { sign_in_provider: string };
	name?: string;
	picture?: string;
};

type WhoAmIResponse =
	| { status: 'success'; data: WhoAmIData }
	| { status: 'error'; statusCode: number; message?: string };

export const getWhoAmI = async (ssoToken?: string): Promise<WhoAmIResponse> => {
	if (!ssoToken) return { status: 'error', statusCode: 401, message: 'no token' };

	try {
		const whoAmIResponse = await fetch(`${process.env.NEXT_PUBLIC_AUTH_ENDPOINT_URL}/who-am-i`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${ssoToken}`,
				'X-brand': `${process.env.NEXT_PUBLIC_API_BRAND}`,
			},
		});

		if (!whoAmIResponse.ok) {
			const message = await whoAmIResponse
				.json()
				.then((r) => JSON.stringify(r))
				.catch(() => whoAmIResponse.statusText);

			return { status: 'error', statusCode: whoAmIResponse.status, message };
		}

		const data: WhoAmIData = await whoAmIResponse.json();
		return { status: 'success', data };
	} catch (e) {
		const errorMessage = parseErrorToReport(e, 'Unexpected error getting whoAmIData');

		// eslint-disable-next-line no-console
		console.error(errorMessage);
		errorHandler.report(errorMessage);

		return { status: 'error', statusCode: 500, message: 'unexpected error' };
	}
};
