import { GraphQLClient } from 'graphql-request';

// Fallback for Jest jsdom
if (!AbortSignal.timeout) {
	AbortSignal.timeout = (ms: number): AbortSignal => {
		const controller = new AbortController();
		setTimeout(() => controller.abort(new DOMException('TimeoutError')), ms);
		return controller.signal;
	};
}

export const DavinciClient = new GraphQLClient(process.env.NEXT_PUBLIC_DAVINCI_BOOKINGS_ENDPOINT_URL as string);
