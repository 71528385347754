import { gql } from 'graphql-request';

import { DavinciClient } from './DavinciClient';

export type BookingDocumentData = {
	fileObject: string;
};

export type BookingDocumentQueryResponse = { bookingDocuments: BookingDocumentData[] };

export const getBookingDocumentById = async (
	token: string,
	bookingDocumentId: number,
): Promise<BookingDocumentQueryResponse | undefined> => {
	try {
		return await DavinciClient.request<BookingDocumentQueryResponse, { bookingDocumentId: number }>({
			signal: AbortSignal.timeout(20000),
			document: bookingDocumentByIdQuery,
			variables: { bookingDocumentId: bookingDocumentId },
			requestHeaders: { Authorization: `Bearer ${token}` },
		});
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(`Error fetching booking document for booking document id ${bookingDocumentId}`, error);
	}
};

const bookingDocumentByIdQuery = gql`
	query GetBookingDocumentById($bookingDocumentId: Int) {
		bookingDocuments(where: { bookingDocumentId: { _eq: $bookingDocumentId } }) {
			fileObject
		}
	}
`;
