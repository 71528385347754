import { ReactNode, FC } from 'react';

import Head from 'next/head';

import Footer from '../blocks/footer/Footer';
import Header from '../blocks/header/Header';

export type Props = {
	children: ReactNode;
	title: string;
};

const ErrorLayout: FC<Props> = ({ children, title }) => {
	return (
		<>
			<Head>
				<title>{title}</title>
			</Head>
			<Header pageType="errorPage" />
			{children}
			<Footer pageType="errorPage" />
		</>
	);
};

export default ErrorLayout;
