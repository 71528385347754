import { gql, request } from 'graphql-request';

import {
	GetUserProfileResponse,
	UpdateUserProfileData,
	UpdateUserProfileResponse,
	InsertUserData,
	InsertUserResponse,
	QueryUserVariables,
	UpsertSocialUserData,
} from './profileInterfaces';

export const profileQuery = gql`
	query GetUserByPk($user_id: String!) {
		user_by_pk(id: $user_id) {
			user_personal_datum {
				first_name
				last_name
				gender
				date_of_birth
				address_zipcode
				address_housenumber
				address_housenumber_addition
				address_street_name
				address_city
				address_country
				phone
			}
			email_address
		}
	}
`;

export const insertUserMutation = gql`
	mutation InsertUser($object: user_personal_data_insert_input = {}) {
		insert_user_personal_data_one(object: $object) {
			user_id
		}
	}
`;

export const upsertUserMutation = gql`
	mutation UpsertUser($object: user_personal_data_insert_input = {}) {
		insert_user_personal_data_one(
			object: $object
			on_conflict: { constraint: user_personal_data_pkey, update_columns: [email_address] }
		) {
			user_id
		}
	}
`;

export const profileMutation = gql`
	mutation MyMutation(
		$user_id: String!
		$first_name: String
		$last_name: String
		$gender: user_gender_enum
		$date_of_birth: date
		$address_zipcode: String
		$address_housenumber: String
		$address_housenumber_addition: String
		$address_street_name: String
		$address_city: String
		$address_country: user_country_enum
		$phone: String
	) {
		update_user_personal_data_by_pk(
			pk_columns: { user_id: $user_id }
			_set: {
				first_name: $first_name
				last_name: $last_name
				gender: $gender
				date_of_birth: $date_of_birth
				address_zipcode: $address_zipcode
				address_housenumber: $address_housenumber
				address_housenumber_addition: $address_housenumber_addition
				address_street_name: $address_street_name
				address_city: $address_city
				address_country: $address_country
				phone: $phone
			}
		) {
			user_id
		}
	}
`;

export const getUserProfile = async (
	user_id: string,
	token: string | undefined = '',
): Promise<GetUserProfileResponse> => {
	return await request<GetUserProfileResponse, { user_id: string }>(
		process.env.NEXT_PUBLIC_HASURA_USERS_ENDPOINT_URL as string,
		profileQuery,
		{ user_id },
		{ Authorization: `Bearer ${token}` },
	);
};

export const updateUserProfile = async (data: UpdateUserProfileData): Promise<UpdateUserProfileResponse> => {
	return await request<UpdateUserProfileResponse, Omit<UpdateUserProfileData, 'token'>>(
		process.env.NEXT_PUBLIC_HASURA_USERS_ENDPOINT_URL as string,
		profileMutation,
		{
			user_id: data.user_id,
			first_name: data.first_name,
			last_name: data.last_name,
			gender: data.gender,
			date_of_birth: data.date_of_birth,
			address_zipcode: data.address_zipcode,
			address_housenumber: data.address_housenumber,
			address_housenumber_addition: data.address_housenumber_addition,
			address_street_name: data.address_street_name,
			address_city: data.address_city,
			address_country: data.address_country,
			phone: data.phone,
		},
		{ Authorization: `Bearer ${data.token}` },
	);
};

export const insertUser = async ({
	id,
	email_address,
	token,
	first_name,
	last_name,
}: InsertUserData): Promise<InsertUserResponse> => {
	return await request<InsertUserResponse, QueryUserVariables>(
		process.env.NEXT_PUBLIC_HASURA_USERS_ENDPOINT_URL as string,
		insertUserMutation,
		{
			object: {
				first_name,
				last_name,
				user: {
					data: { id, email_address },
				},
			},
		},
		{ Authorization: `Bearer ${token}` },
	);
};

export const upsertSocialUser = async ({
	id,
	first_name,
	last_name,
	email_address,
	token,
}: UpsertSocialUserData): Promise<InsertUserResponse> => {
	return await request<InsertUserResponse, QueryUserVariables>(
		process.env.NEXT_PUBLIC_HASURA_USERS_ENDPOINT_URL as string,
		upsertUserMutation,
		{
			object: {
				first_name,
				last_name,
				user: {
					data: { id, email_address },
				},
			},
		},

		{ Authorization: `Bearer ${token}` },
	);
};
